import { DateFormaterSans } from '../../../shared/functions/date'
import httpCommon from '../../../shared/services/http-common'
import { Domaine } from '../../Domaine/interfaces/domaine.interface'
import { CreateAdminDto, User, UserRole, UtilisateurPrestataireForList } from '../interfaces/utilisateur.interface'
import ExcelJS from 'exceljs'

class UserServices {
    getAll(page: number) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users?page=${page}`)
    }
    getAllUsers() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/listeUsers`)
    }

    getById(userId: User['_id']) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users/one/${userId}`)
    }
    getByEmail(userEmail: User['email']) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users/${userEmail}`)
    }
    getByCreateurRoleMulti(page: number, roleId: UserRole['_id'], limit: number, name?: string,nomGerant?:string, domaineId?: Domaine['_id'], needTva?: boolean, _id?: string,ville?:string,niveau?:string,shouldBePayeur?:boolean,chefDEquipe?:boolean, query?: any): Promise<any> {
        let url: string[] = []
        if (name) url.push(`name=${name}`)
        if (nomGerant) url.push(`nomGerant=${nomGerant}`)
        if (domaineId) url.push(`domaineId=${domaineId}`)
        // if(typeof needTva === "boolean") url.push(`needTva=${needTva}`)
        // if(needTva) url.push(`needTva=${needTva}`)
        // console.log("needTva's VALUE IN SERVICE: "+needTva)
        if(needTva) url.push(`needTva=${needTva}`)
        if(_id) url.push(`_id=${_id}`)
        if(ville) url.push(`ville=${ville}`)
        if(niveau) url.push(`statusUser=${niveau}`)
        if(shouldBePayeur) url.push(`shouldBePayer=${shouldBePayeur}`)
        // shouldBePayer (pas shouldBePayeur)
        if(chefDEquipe) url.push(`chefDEquipe=${chefDEquipe}`)
        if (query) {
            for (const key of Object.keys(query)) {
                if (query[key] && query[key] !== '') {
                    url.push(`${key}=${query[key]}`)
                }
            }
        }
        return httpCommon.get(
            `${process.env.REACT_APP_API_ROOT}/users/by-role/${roleId}/${page}/${limit}${url.length > 0 ? '?' + url.join('&') : ''}`
        )
    }

    getByCreateurRole(page: number, roleId: UserRole['_id'], limit: number, name?: string, domaineId?: Domaine['_id'], needTva?: boolean, _id?: string,ville?:string,niveau?:string,shouldBePayeur?:boolean,chefDEquipe?:boolean, query?: any): Promise<any> {
        let url: string[] = []
        if (name) url.push(`name=${name}`)
        if (domaineId) url.push(`domaineId=${domaineId}`)
        // if(typeof needTva === "boolean") url.push(`needTva=${needTva}`)
        // if(needTva) url.push(`needTva=${needTva}`)
        // console.log("needTva's VALUE IN SERVICE: "+needTva)
        if(needTva) url.push(`needTva=${needTva}`)
        if(_id) url.push(`_id=${_id}`)
        if(ville) url.push(`ville=${ville}`)
        if(niveau) url.push(`statusUser=${niveau}`)
        if(shouldBePayeur) url.push(`shouldBePayer=${shouldBePayeur}`)
        // shouldBePayer (pas shouldBePayeur)
        if(chefDEquipe) url.push(`chefDEquipe=${chefDEquipe}`)
        if (query) {
            for (const key of Object.keys(query)) {
                if (query[key] && query[key] !== '') {
                    url.push(`${key}=${query[key]}`)
                }
            }
        }
        return httpCommon.get(
            `${process.env.REACT_APP_API_ROOT}/users/by-role/${roleId}/${page}/${limit}${url.length > 0 ? '?' + url.join('&') : ''}`
        )
    }

    getByRole(page: number, roleId: UserRole['_id'], limit: number, name?: string, domaineId?: Domaine['_id'], needTva?: boolean, _id?: string,ville?:string,niveau?:string,shouldBePayeur?:boolean, query?: any): Promise<any> {
        let url: string[] = []
        if (name) url.push(`name=${name}`)
        if (domaineId) url.push(`domaineId=${domaineId}`)
        // if(typeof needTva === "boolean") url.push(`needTva=${needTva}`)
        // if(needTva) url.push(`needTva=${needTva}`)
        // console.log("needTva's VALUE IN SERVICE: "+needTva)
        if(needTva) url.push(`needTva=${needTva}`)
        if(_id) url.push(`_id=${_id}`)
        if(ville) url.push(`ville=${ville}`)
        if(niveau) url.push(`statusUser=${niveau}`)
        if(shouldBePayeur) url.push(`shouldBePayer=${shouldBePayeur}`)
        // shouldBePayer (pas shouldBePayeur)
        if (query) {
            for (const key of Object.keys(query)) {
                if (query[key] && query[key] !== '') {
                    url.push(`${key}=${query[key]}`)
                }
            }
        }
        return httpCommon.get(
            `${process.env.REACT_APP_API_ROOT}/users/by-role/${roleId}/${page}/${limit}${url.length > 0 ? '?' + url.join('&') : ''}`
        )
    }

    getByRoleId(roleId: UserRole['_id']) {
        return httpCommon.get(
            `${process.env.REACT_APP_API_ROOT}/userbyrole/${roleId}`
        )
    }

    getUniqueCiteEPsOuVilles() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users/getvilles`)
      }

    verifierConfirmationSuppression(id: User['_id'],email: User['email'], password: User['password']) {
        return httpCommon.post(
            `${process.env.REACT_APP_API_ROOT}/users/verification_confirmation_suppression`,{
                _id: id,
                email: email,
                password: password
              }
        )
      }

    getASuppr(page: number, limit: number,email: string): Promise<any> {
        let url: string[] = []
        // if (name) url.push(`name=${name}`)
        // if (domaineId) url.push(`domaineId=${domaineId}`)
        // if(typeof needTva === "boolean") url.push(`needTva=${needTva}`)
        // if(needTva) url.push(`needTva=${needTva}`)
        // console.log("needTva's VALUE IN SERVICE: "+needTva)
        // if(needTva) url.push(`needTva=${needTva}`)
        // if(_id) url.push(`_id=${_id}`)
        if (email) url.push(`email=${email}`)
        // if (query) {
        //     for (const key of Object.keys(query)) {
        //         if (query[key] && query[key] !== '') {
        //             url.push(`${key}=${query[key]}`)
        //         }
        //     }
        // }
        return httpCommon.get(
            `${process.env.REACT_APP_API_ROOT}/users/find/a_suppr/${page}/${limit}${url.length > 0 ? '?' + url.join('&') : ''}`
        )
    }

    deleteAUser(userId: User['_id']) {
        return httpCommon.delete(`${process.env.REACT_APP_API_ROOT}/users/${userId}`)
    }

    getRoles(): Promise<any> {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users/roles`)
    }

    createAdmin(params: CreateAdminDto): Promise<any> {
        return httpCommon.post(`${process.env.REACT_APP_API_ROOT}/users/create-admin`, params)
    }

    getAllAdmin(): Promise<any> {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users/getall-admin`)
    }
    updatePresta(data: any){
        return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/users/update`, data)
    }
    updateAdminEmail(_id: User['_id'], email?: User['email']){
        return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/users/updateAdminEmail`, { _id, email })
    }
    getAllStatusByRole(roleId: UserRole['_id']) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/statusUserByRole/${roleId}`)
    }
    getAllStatus() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/statusUser`)
    }
    
    async exportToExcelFile(classeur: ExcelJS.Workbook, feuille: ExcelJS.Worksheet, lignes: any[], nomFichier: string) {
        feuille.addRows(lignes);
        
        feuille.getRow(1).font = { bold: true, size: 12 }
        feuille.getRow(1).alignment = { horizontal: "center", vertical: "middle" }

        await classeur.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = nomFichier;
            link.click();
        });
    }

    async exportExcelPrestataire(prestataires: UtilisateurPrestataireForList[]) {
        const nomFichier = "exported_prestataire.xlsx"
        const classeur = new ExcelJS.Workbook()
        const feuilleDeCalcul = classeur.addWorksheet('Feuille 1')

        feuilleDeCalcul.columns = [
            { header: "Nom", key: "firstname", width: 15 },
            { header: "Prénom", key: "lastname", width: 15 },
            { header: "Niveau", key: "niveau", width: 20 },
            { header: "Email", key: "email", width: 30 },
            { header: "Téléphone", key: "numero", width: 20 },
            { header: "Ville", key: "ville", width: 20 },
            { header: "Trajet", key: "trajet", width: 10 },
            { header: "Siret", key: "siret", width: 20 },
            { header: "Créé le", key: "createdAt", width: 12 },
        ];

        const lignes = prestataires.map((element) => ({
            firstname: element.firstname,
            lastname: element.lastname,
            niveau: element.statusUser,
            email: element.email,
            numero: "+33" + element.numero,
            ville: element.localisation[0]?.address || "",
            trajet: element.trajet[0] + " à " + element.trajet[1],
            siret: element.idEntreprise?.siretEntreprise || "",
            createdAt: DateFormaterSans(element.createdAt.toString())
        }));
        
        this.exportToExcelFile(classeur, feuilleDeCalcul, lignes, nomFichier);

        console.log("Fichier excel prestataire généré");
    }

    async exportExcelCréateurs(createurs: User[]) {
        const nomFichier = "exported_createurs.xlsx"
        const classeur = new ExcelJS.Workbook()
        const feuilleDeCalcul = classeur.addWorksheet('Feuille 1')

        feuilleDeCalcul.columns = [
            { header: "Nom", key: "firstname", width: 15 },
            { header: "Prénom", key: "lastname", width: 15 },
            { header: "Niveau", key: "niveau", width: 20 },
            { header: "Email", key: "email", width: 30 },
            { header: "Téléphone", key: "numero", width: 20 },
            { header: "Ville", key: "ville", width: 20 },
            { header: "Domaine", key: "domaine", width: 20 },
            { header: "Entreprise", key: "entreprise", width: 20 },
            { header: "Siret", key: "siret", width: 20 },
            { header: "Créé le", key: "createdAt", width: 12 },
        ];

        const lignes = createurs.map((element) => ({
            firstname: element.firstname,
            lastname: element.lastname,
            niveau: element.statusUser,
            email: element.email,
            numero: "+33" + element.numero,
            ville: element.idEntreprise?.citeEP || "",
            domaine: element.idEntreprise?.domaineEntreprise?.name || "",
            entreprise: element.idEntreprise?.nomEntreprise || "",
            siret: element.idEntreprise?.siretEntreprise || "",
            createdAt: DateFormaterSans(element.createdAt.toString())
        }));
        
        this.exportToExcelFile(classeur, feuilleDeCalcul, lignes, nomFichier);

        console.log("Fichier excel prestataire généré");
    }

    async exportExcelCréateursMulti(createursMulti: User[]) {
        const nomFichier = "exported_createurs_multi.xlsx"
        const classeur = new ExcelJS.Workbook()
        const feuilleDeCalcul = classeur.addWorksheet('Feuille 1')

        feuilleDeCalcul.columns = [
            { header: "Nom", key: "firstname", width: 15 },
            { header: "Prénom", key: "lastname", width: 15 },
            { header: "Email", key: "email", width: 30 },
            { header: "Téléphone", key: "numero", width: 20 },
            { header: "Chef d'équipe", key: "chefEquipe", width: 20 },
            { header: "Ville", key: "ville", width: 20 },
            { header: "Domaine", key: "domaine", width: 15 },
            { header: "Entreprise", key: "entreprise", width: 20 },
            { header: "Créé le", key: "createdAt", width: 12 },
        ];

        const lignes = createursMulti.map((element) => ({
            firstname: element.firstname,
            lastname: element.lastname,
            email: element.email,
            numero: "+33" + element.numero,
            chefEquipe: element.idEntreprise?.nomGerant || "",
            ville: element.idEntreprise?.citeEP || "",
            domaine: element.idEntreprise?.domaineEntreprise?.name || "",
            entreprise: element.idEntreprise?.nomEntreprise || "",
            createdAt: DateFormaterSans(element.createdAt.toString())
        }));
        
        this.exportToExcelFile(classeur, feuilleDeCalcul, lignes, nomFichier);

        console.log("Fichier excel prestataire généré");
    }
}

const userServices = new UserServices()

export default userServices
